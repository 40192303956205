export default function listVariables() {
    // To get parameters in the url
    function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    // DataTables initialisation https://www.datatables.net
    var currentlang = $('html').attr('lang');
    var datatablei18n = new Array();
    if (currentlang == 'fr') {
        datatablei18n['language'] =
            {
                all: "Tous",
                none: "Aucun",
                processing: "Traitement en cours...",
                search: "Rechercher&nbsp;:",
                lengthMenu: "Afficher _MENU_ &eacute;l&eacute;ments",
                info: "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
                infoEmpty: "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
                infoFiltered: "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
                infoPostFix: "",
                loadingRecords: "Chargement en cours...",
                zeroRecords: "Aucun &eacute;l&eacute;ment &agrave; afficher",
                emptyTable: "Aucune donnée disponible dans le tableau",
                paginate: {
                    first: "Premier",
                    previous: "Pr&eacute;c&eacute;dent",
                    next: "Suivant",
                    last: "Dernier"
                },
                aria: {
                    sortAscending: ": activer pour trier la colonne par ordre croissant",
                    sortDescending: ": activer pour trier la colonne par ordre décroissant"
                }
            };
    } else {
        datatablei18n['language'] =
            {
                all: "Alle",
                none: "Geen",
                processing: "Bezig...",
                search: "Zoeken:",
                lengthMenu: "_MENU_ resultaten weergeven",
                info: "_START_ tot _END_ van _TOTAL_ resultaten",
                infoEmpty: "Geen resultaten om weer te geven",
                infoFiltered: "(gefilterd uit _MAX_ resultaten)",
                infoPostFix: "",
                loadingRecords: "Aan het laden...",
                zeroRecords: "Geen resultaten gevonden",
                emptyTable: "Geen resultaten aanwezig in de tabel",
                paginate: {
                    first: "Eerste",
                    previous: "Vorige",
                    next: "Volgende",
                    last: "Laatste"
                },
                aria: {
                    sortAscending: ": sorteren de kolom in oplopende volgorde",
                    sortDescending: ": sorteren de kolom in aflopende volgorde"
                }
            };
    }


    if ($("#table-filtered").length) {
        var table = $("#table-filtered").DataTable({
            "order": [[0, "asc"]],
            rowReorder: true,
            "lengthMenu": [[-1, 10, 25, 50], [datatablei18n['language'].all, 10, 25, 50]],
            language: {
                all: datatablei18n['language'].all,
                processing: datatablei18n['language'].processing,
                search: datatablei18n['language'].search,
                lengthMenu: datatablei18n['language'].lengthMenu,
                info: datatablei18n['language'].info,
                infoEmpty: datatablei18n['language'].infoEmpty,
                infoFiltered: datatablei18n['language'].infoFiltered,
                infoPostFix: "",
                loadingRecords: datatablei18n['language'].loadingRecords,
                zeroRecords: datatablei18n['language'].zeroRecords,
                emptyTable: datatablei18n['language'].emptyTable,
                paginate: {
                    first: datatablei18n['language'].paginate.first,
                    previous: datatablei18n['language'].paginate.previous,
                    next: datatablei18n['language'].paginate.next,
                    last: datatablei18n['language'].paginate.last,
                },
                aria: {
                    sortAscending: datatablei18n['language'].sortAscending,
                    sortDescending: datatablei18n['language'].sortDescending,
                }
            }
        });


        // General function to search when we click on a letter on a-z filter.
        // Store the letter or number to search
        var _alphabetSearch = undefined;
        // https://datatables.net/manual/plug-ins/search
        $.fn.dataTable.ext.search.push(
            function (settings, searchData, index, rowData, counter) {
                var min = 0;
                var max = 9;
                // we have a number 0-9 filter element
                if (!isNaN(_alphabetSearch)) {
                    // We have a digit
                    if (searchData[0].charAt(0) >= min && searchData[0].charAt(0) <= max) {
                        return true;
                    } else {
                        return false;
                    }

                }
                // We have a letter a-z filter element
                if (isNaN(_alphabetSearch)) {

                    if (searchData[0].charAt(0).toUpperCase() === _alphabetSearch || !_alphabetSearch) {
                        return true;
                    }
                }
                return false;
            }
        );

        // Create alphabetical list
        var alphabet = $('<div class="alphabet" style="display:none;"/>');

        var filterIndexActive = new Array();
        // Populate the filterIndexActive array
        $("#table-filtered tbody > tr").find("td:first-child").each(function () {
            filterIndexActive.push($(this).find("a").text().charAt(0));
        });

        var letter;
        for (var i = 0; i < 26; i++) {
            letter = String.fromCharCode(65 + i);

            $('<button class="btn btn-link" />')
                .data('letter', letter)
                .html(letter)
                .appendTo(alphabet);
        }
        // A-Z  letters filter
        var filterAnchor = alphabet.find("button");
        var letterToTest;
        filterAnchor.each(function () {
            letterToTest = $(this).text();
            if (jQuery.inArray(letterToTest, filterIndexActive) === -1) {
                $(this).contents().unwrap().wrap('<button class="btn btn-link" disabled>');
            }

        });

        // Add all and 0-9 filters to list
        alphabet.prepend('<button class="btn btn-link active">' + datatablei18n['language'].all + '</button><button class="btn btn-link numbers-filter">[0-9]</button>').prepend(datatablei18n['language'].search);
        alphabet.insertAfter($("#filters"));

        // 0-9 filter
        var numberToTest = Array.apply(null, Array(10));
        numberToTest = numberToTest.map(function (x, i) {
            return i.toString();
        });
        numberToTest.forEach(function (number) {
            if (jQuery.inArray(number, filterIndexActive) !== -1) {
                $(".numbers-filter").contents().unwrap().wrap('<button class="btn btn-link numbers-filter">');
            }
        });
        // On click on each span (span == we have results with this letter)
        // We must send the selected letter to the search.
        alphabet.on("click", ".btn-link", function () {
            alphabet.find(".active").removeClass("active");
            $(this).addClass("active");
            if ($(this).hasClass("numbers-filter")) {
                _alphabetSearch = "5"; // 5 number is not important, it is only to give a number to the search function.
            } else {
                _alphabetSearch = $(this).data("letter");
            }

            table.draw();
        });


        /*// Datapicker bootstrap
        if ( $( ".list-variables" ).length ) {
            $( ".date-pick" ).datepicker({});
        }*/

        // form all variables
        var filter = getParameterByName("filter");
        var source = getParameterByName("source");
        var themes = getParameterByName("theme");
        var institution = getParameterByName("institution");
        var dateFrom = getParameterByName("from");
        var dateTo = getParameterByName("to");

        $("." + filter).show();
        $("#filter").val(filter);
        $("#sources").val(source);
        $("#themes").val(themes);
        $("#institution").val(institution);
        $("#date-from").val(dateFrom);
        $("#date-to").val(dateTo);
        // default if we have name as filter or is null
        if (filter === 'name' || filter === null) {
            $("#filter").val('name');
            $(".alphabet").show();
        } else {
            $('button.btn-filter').show();
        }

        // on change, we show/hide others select
        $("#filter").change(function () {
            var selectToShow;

            if ($("#filter").val() != 'name') {
                selectToShow = '.' + $("#filter").val();
            } else {
                // We submit the form for showing all variables
                $('button.btn-filter').click().hide();
            }

            $("div.form-elems, .alphabet").hide();
            $(selectToShow).show();
            $('button.btn-filter').show();
        });
    } // end datatable
}
