'use strict';
const $ = require('jquery');
window.$ = $;
window.jQuery = $;

import '../css/index.scss';
require('bootstrap');
//require('datatables.net');
require('datatables.net-bs4');
require('jquery-match-height');
import adminMenu from '@elasticms/admin-menu';
import listVariables from './components/listVariables';

adminMenu();

$(function(){
    // homepage
    $('#home-menu .category').matchHeight();
    $('#home-menu .list-unstyled').matchHeight();
    $('#home-menu .description').matchHeight();

    // list by variables
    listVariables();

    $('[data-toggle="tooltip"]').tooltip();

    const $partners = $('#partners');
    if($partners.length) {
        for (let i = $partners.children().length; i >= 0; i--) {
            $partners.append($partners.children()[Math.random() * i | 0]);
        }
    }

    // function to open attachment tab
    function showAttachment (attachment) {
        $('a[href="#appendix"]').tab('show');
        // scroll to attachment
        var target = $( "#" + attachment );
        target = target.length ? target : $( "[name='" + attachment + "']" );
        if (target.length) {
            $( "html, body" ).animate({
                scrollTop: target.offset().top - 10
            }, 800);
            return false;
        }
    }

    // Attachment links anchors for variables display
    $( '.tab-content a[href*="#attachment-"]:not([href="#"])' ).click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            showAttachment(this.hash.slice(1));
        }
    });

    // Check if we have a hash into the url
    var url = window.location.hash;
    var hash = url.substring(1);
    if( url && url.indexOf(hash) !== -1  && hash.indexOf("attachment-") !== 1 ) {
        // open appendix tab
        $('a[href="#appendix"]').tab('show');
        // hash attachment exist
        showAttachment(hash);
    }
});
